<template>
  <div class="prentView orderQueryView">
    <div class="topView">
      <div class="backView">
        <el-page-header
          v-if="isView"
          @back="back"
          title="返回"
          content="商品零售"
        />
        <el-page-header
          v-else
          @back="$router.go(-1)"
          title="返回"
          content="商品零售"
        />
      </div>
    </div>

    <div class="contentView">
      <div
        class="leftView"
        style="padding-right: 500px;"
        v-loading="getLoading"
        element-loading-text="加载中"
      >
        <div class="sellInfoView" v-if="isView">
          <div class="cusView">
            <div class="tView">顾客信息</div>
            <div class="infoView">
              <div class="inputView">
                <label>联系方式</label>
                <input disabled v-model="userInfo.phone" />
              </div>
              <div class="inputView">
                <span class="stars">*</span>
                <label>姓名</label>
                <input v-model="userInfo.realName" />
              </div>
              <div class="inputView">
                <label>性别</label>
                <input :value="'男'" disabled v-if="userInfo.gender == 1" />
                <input :value="'女'" disabled v-if="userInfo.gender == 2" />
              </div>
              <div class="inputView">
                <label>出生日期</label>
                <input :value="userInfo.birthday" disabled />
              </div>
              <div class="inputView" v-if="this.userInfo.memberUser">
                <label>会员折扣</label>
                <span style="font-size:12px;transform:scale(0.9)">{{
                  "全净洗:" +
                    userInfo.memberUser.discount / 10 +
                    "折" +
                    ",奢护洗:" +
                    userInfo.memberUser.luxuryDiscount / 10 +
                    "折" +
                    ",保值洗:" +
                    userInfo.memberUser.hedgingDiscount / 10 +
                    "折"
                }}</span>
              </div>
              <div class="inputView" v-if="this.userInfo.memberUser">
                <label>洗鞋权益</label>
                <input
                  v-if="userInfo.memberUser.memberUserBenefits"
                  disabled
                  :value="
                    userInfo.memberUser.memberUserBenefits.length > 0
                      ? '是'
                      : '否'
                  "
                />
                <input v-else disabled value="否" />
              </div>
              <div class="inputView">
                <label>最近消费</label>
                <div
                  v-if="this.userInfo.lastMoneyRecord"
                  class="inputView-date"
                >
                  {{ this.userInfo.lastMoneyRecord.createTime | date }}

                  <label class="query-result-main-label inputView-label">
                    {{ this.userInfo.lastMoneyRecord.storeName }}
                  </label>
                </div>
              </div>
              <div class="inputView">
                <label>标签</label>
                <div>
                  <span v-if="tagList.length > 3">
                    <el-tag size="mini">{{ tagList[0] }}</el-tag>
                    <el-tag size="mini">{{ tagList[1] }}</el-tag>
                    <el-tag size="mini">{{ tagList[2] }}</el-tag>
                  </span>
                  <span v-else>
                    <el-tag
                      size="mini"
                      v-for="(item, index) in tagList"
                      :key="index"
                    >
                      {{ item }}
                    </el-tag>
                  </span>
                </div>
                <p @click="more">
                  更多
                  <i class="el-icon-arrow-right"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="accView">
            <div class="tView">账户信息</div>
            <div class="buyInfoView">
              <div class="amtView">
                <div class="inputView" v-if="this.userInfo.memberUser">
                  <label>储值总余额</label>
                  <input
                    placeholder="0"
                    disabled
                    :value="
                      userInfo.memberUser.balance
                        ? userInfo.memberUser.balance
                        : 0 + '元'
                    "
                  />
                </div>
                <div class="inputView" v-else>
                  <label>储值总余额</label>
                  <input placeholder="0元" disabled />
                </div>

                <div class="inputView" v-if="userInfo.memberUser">
                  <label>通享卡余额</label>
                  <input
                    :value="
                      userInfo.memberUser.headquartersBalance
                        ? userInfo.memberUser.headquartersBalance
                        : 0 + '元'
                    "
                    disabled
                  />
                </div>
                <div class="inputView" v-else>
                  <label>通享卡余额</label>
                  <input placeholder="0元" disabled />
                </div>

                <div class="inputView" v-if="userInfo.memberUser">
                  <label>本店历史余额</label>
                  <input
                    :value="
                      userInfo.memberUser.currStoreBalance
                        ? userInfo.memberUser.currStoreBalance
                        : 0 + '元'
                    "
                    disabled
                  />
                </div>
                <div class="inputView" v-else>
                  <label>本店历史余额</label>
                  <input placeholder="0元" disabled />
                </div>

                <div class="inputView">
                  <label>会员到期时间</label>
                  <input
                    v-if="userInfo.memberUser && userInfo.memberUser.expireDate"
                    disabled
                    :value="userInfo.memberUser.expireDate | date1"
                  />
                </div>
                <div class="inputView">
                  <label>会员剩余时间</label>
                  <input
                    v-if="userInfo.memberUser"
                    disabled
                    :value="userInfo.memberUser.expireDay"
                  />
                </div>
                <div class="inputView">
                  <label>待取衣物</label>
                  <input
                    placeholder="0"
                    disabled
                    :value="userInfo.waitTakeClothes"
                  />
                </div>
                <div class="inputView">
                  <label>消费次数</label>
                  <input
                    placeholder="0"
                    disabled
                    :value="userInfo.consumeTimes"
                  />
                </div>
                <div class="inputView" v-if="userInfo.memberUser">
                  <label>权益优惠</label>
                  <input
                    placeholder="0"
                    disabled
                    :value="userInfo.memberUser.totalDiscountAmount + '元'"
                  />
                </div>
                <div class="inputView" v-else>
                  <label>权益优惠</label>
                  <input placeholder="0元" disabled />
                </div>
              </div>
              <div class="btnView">
                <el-button type="primary" @click="handleGet">
                  买商品
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="query-no" v-if="!isView">
          <div>
            <img src="../../../assets/img/icon-coat-hanger.png" />
            <div>在页面右侧输入查询内容</div>
          </div>
        </div>
      </div>
      <div class="rightView">
        <div class="queryView">
          <div class="input-contentView">
            <div class="popView-headerView">用户查询</div>
            <div class="inputView" style="margin-top: 30px;">
              <input
                ref="phone"
                class="input"
                v-model="phone"
                placeholder="请输入用户卡号或手机号"
                @keyup.enter="_handleConfirmKey"
              />
            </div>
            <div class="contentView">
              <div class="keyboardView" @click.stop="_handleKeyPress">
                <div class="keyItem btn-audio key-1">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_1.png');
                    "
                    data-num="1"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-2">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_2.png');
                    "
                    data-num="2"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-3">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_3.png');
                    "
                    data-num="3"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-4">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_4.png');
                    "
                    data-num="4"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-5">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_5.png');
                    "
                    data-num="5"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-6">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_6.png');
                    "
                    data-num="6"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-7">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_7.png');
                    "
                    data-num="7"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-8">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_8.png');
                    "
                    data-num="8"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-9">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_9.png');
                    "
                    data-num="9"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-Point">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_Point.png');
                    "
                    data-num="."
                  ></div>
                </div>
                <div class="keyItem btn-audio key-0">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://img.xiyitong.net/wisdomDesk/PriceKey_0.png');
                    "
                    data-num="0"
                  ></div>
                </div>
                <div class="keyItem btn-audio key-Del">
                  <div
                    class="valView"
                    style="
                      background-image: url('https://static.bokao2o.com/wisdomDesk/images/PriceKey_Del.png');
                    "
                    data-num="D"
                  ></div>
                </div>
              </div>
              <div class="btnView">
                <button class="btn-clean btn-audio" @click="_handleClearKey">
                  <div class="valView">重输</div>
                </button>
                <button class="btn-comp btn-audio" @click="_handleConfirmKey">
                  确认
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--标签-->
    <el-dialog
      title="标签"
      :visible.sync="tagDialogVisible"
      center
      width="60%"
      append-to-body
      ref="query"
    >
      <div>
        <el-tag
          style="margin-top: 10px;"
          v-for="tag in tagList"
          :key="tag"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>

        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        ></el-input>
        <el-button
          v-else
          class="button-new-tag"
          style="margin-top: 10px;"
          size="small"
          @click="showInput"
        >
          + 新增标签
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="postPutTag" size="medium">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isMobile, isPhone } from "@/utils/validate";
import { getUserInfo, putTag } from "@/api/store/user";
import parseTime from "@/utils/date";

export default {
  name: "getQuery",
  data() {
    return {
      parseTime,
      isView: false,
      getLoading: false,
      inputVisible: false,
      phone: "",
      userInfo: {
        userId: "",
        phone: "",
        realName: "",
        memberUser: {},
        birthday: "",
        packageId: "",
        useService: "",
        userType: "",
        packageType: "",
        clothesNum: "",
        dealTitle: "",
        receiptCode: "",

        // userType: 0,
        address: "",
        addressLabel: "",
        lastMoneyRecord: {
          createTime: "",
          storeName: "",
          sourceName: "",
          date: "",
          money: "",
        },
      },
      dynamicTags: [],
      inputValue: "",
      tagList: [],
      // 标签
      tagDialogVisible: false,
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.$refs.phone.focus();
    });
  },
  watch: {},
  methods: {
    back() {
      this.isView = false;
    },
    //处理按键
    _handleKeyPress(e) {
      this.$refs.phone.focus();
      console.log(e);
      let num = e.target.dataset.num;
      //不同按键处理逻辑
      // -1 代表无效按键，直接返回
      if (num == undefined) return false;
      switch (String(num)) {
        //小数点
        case ".":
          this._handleDecimalPoint();
          break;
        //删除键
        case "D":
          this._handleDeleteKey();
          break;
        //清空键
        case "C":
          this._handleClearKey();
          break;
        //确认键
        case "S":
          this._handleConfirmKey();
          break;
        default:
          this._handleNumberKey(num);
          break;
      }
    },
    //处理小数点函数
    _handleDecimalPoint() {
      //如果包含小数点，直接返回
      if (this.phone.indexOf(".") > -1) return false;
      //如果小数点是第一位，补0
      if (!this.phone.length) this.phone = "0.";
      //如果不是，添加一个小数点
      else this.phone = this.phone + ".";
    },
    //处理删除键
    _handleDeleteKey() {
      let S = this.phone;
      //如果没有输入，直接返回
      if (!S.length) return false;
      //否则删除最后一个
      this.phone = S.substring(0, S.length - 1);
    },
    //处理清空键
    _handleClearKey() {
      this.phone = "";
    },
    //处理数字
    _handleNumberKey(num) {
      let S = this.phone;
      //如果有小数点且小数点位数不小于2
      if (S.indexOf(".") > -1 && S.substring(S.indexOf(".") + 1).length < 2)
        this.phone = S + num;
      //没有小数点
      if (!(S.indexOf(".") > -1)) {
        //如果第一位是0，只能输入小数点
        if (num == 0 && S.length == 0) this.phone = "0.";
        else {
          if (S.length && Number(S.charAt(0)) === 0) return;
          this.phone = S + num;
        }
      }
    },
    //提交
    _handleConfirmKey() {
      this.getLoading = true;
      //未输入
      if (this.phone.trim() === "") {
        this.$message.error("请输入手机号/卡号");
        this.getLoading = false;
        return false;
      } else {
        const params = new URLSearchParams();
        params.append("phoneOrCardNum", this.phone);
        getUserInfo(params)
          .then((response) => {
            if (response.data.code == 0) {
              this.userInfo = response.data.data;
              this.dynamicTags = this.tagList =
                response.data.data.userLabelList != null
                  ? response.data.data.userLabelList
                  : [];
              this.isView = true;
            }
            this.getLoading = false;
          })
          .catch(() => {
            this.getLoading = false;
          });
      }
    },
    handleGet() {
      if (!isMobile(this.phone) || !isPhone(this.phone)) {
        if (!this.userInfo.userId) {
          this.$message.error("请输入正确的手机号/电话号码");
          return;
        }
      }
      if (this.userInfo.realName === null) {
        this.$message.error("请输入用户姓名");
        return;
      }
      this.$router.push({
        path: "/home/more/productList",
        query: {
          userId: this.userInfo.userId,
          userType: this.userInfo.userType,
          realName: this.userInfo.realName,
          phone: this.userInfo.phone,
          avator: this.userInfo.avator,
        },
      });
    },
    // 标签更多
    more() {
      this.tagDialogVisible = true;
    },
    handleClose(tag) {
      this.tagList.splice(this.tagList.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tagList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 处理标签
    async postPutTag() {
      try {
        const res = await putTag({
          userId: this.userInfo.userId,
          userLabel: this.tagList,
        });
        this.$message.success(res.data.msg);
        this.tagDialogVisible = false;
      } catch (error) {
        console.log(error);
      }
    },
    cancel() {
      this.tagDialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.prentView {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #fff;
}

.tabs {
  display: flex;
  justify-content: center;

  /deep/ .el-tabs__item {
    font-size: 16px;
  }

  /deep/ .el-tabs__item.is-active {
    color: #3370ff;
    font-weight: bold;
    font-size: 20px !important;
  }
}

/deep/ .el-tabs__nav-wrap::after {
  content: none;
}

.storeDiv {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;

  .storePackage {
    text-align: center;
    width: 248px;
    height: 160px;
    border: 1px solid #dcdfe6;
    border-radius: 8px;
    margin: 5px;

    .imgage {
      height: 100px;
      padding: 5px;
    }
    .cardName {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #000;
      padding: 0 10px;
      .item-name {
        max-width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .active {
    border: 1px solid #3370ff;
  }
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #fff;
}

/deep/ .el-radio__label {
  color: #fff;
}

/deep/ .el-radio__inner {
  margin-left: 20px;
}

.el-input {
  width: 30%;
  margin-right: 20px;
}

.stars {
  display: inline-block;
  color: #ff4600;
  margin-right: 3px;
  margin-top: 3px;
  font-size: 14px;
}

.orderQueryView {
  position: relative;
  background: #fff;
  font-family: PingFangSC-Regular;

  .topView {
    display: flex;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 20px 25px 10px 25px;
    z-index: 100;

    .backView {
      height: 30px;
      flex: 1;

      span {
        cursor: pointer;

        i:nth-child(1) {
          font-size: 22px;
          vertical-align: top;
        }

        i {
          font-style: normal;
        }
      }

      .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    .aliKoubeiView {
      display: flex;
      height: 30px;
      line-height: 30px;

      .btn-kbAuth {
        width: 82px;
        font-size: 16px;
        color: #e7480a;
      }

      button {
        padding: 0;
      }
    }
  }

  .contentView {
    position: relative;
    height: 100%;
    padding-top: 60px;

    .leftView {
      height: 100%;
      .sellInfoView {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: 25px;
        padding-bottom: 10px;
        overflow-x: hidden;
        overflow-y: auto;

        .inputView {
          display: flex;
          justify-content: space-between;
          height: 44px;
          padding: 8px 10px;
          line-height: 28px;
          background: #f8f8f8;
          border-radius: 6px;
          font-size: 15px;
          margin-bottom: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .inputView-date {
            margin-left: 20px;
          }

          .el-tag {
            margin-right: 5px;
          }

          p {
            margin: 0;
            text-align: right;
            cursor: pointer;
          }

          //    }
          input {
            flex: 1;
            background: #f8f8f8;
            text-align: right;
            font-size: 15px;
            color: #333;
            line-height: 28px;
            border: none;
            outline: none;
            padding: 0;
          }
        }

        .cusView {
          width: 380px;
          min-width: 380px;
          padding: 0 55px 35px 55px;
          border-right: 0.5px solid rgba(220, 220, 220, 0.7);
          overflow-x: hidden;
          overflow-y: auto;

          .tView {
            font-family: PingFangSC-Semibold;
            font-size: 20px;
            color: #333;
            line-height: 30px;
            margin-left: -25px;
            margin-bottom: 25px;
          }

          .cardView {
            position: relative;
            width: 270px;
            height: 170px;
            margin-bottom: 15px;

            img {
              width: 270px;
              height: 170px;
              border-radius: 6px;
            }

            .cardNameView {
              position: absolute;
              left: 15px;
              top: 15px;
              line-height: 25px;
              font-family: PingFangSC-Semibold;
              font-size: 18px;
              color: #fff;
            }
          }

          .infoView {
            div {
              display: flex;
              align-items: center;
              background: #f8f8f8;
              border-radius: 6px;
              font-size: 15px;

              label {
                color: #666;
              }
            }

            .inputView-label {
              text-align: right;
              margin-left: 10px;
            }
          }
        }

        .accView {
          flex: 1;
          padding: 0 0 15px 0;
          overflow-x: hidden;
          overflow-y: auto;

          .tView {
            font-family: PingFangSC-Semibold;
            font-size: 20px;
            color: #333;
            line-height: 30px;
            margin-left: -25px;
            margin-bottom: 25px;
            padding: 0 55px;
          }

          .buyInfoView {
            padding: 0 40px;
            padding-bottom: 35px;

            .amtView {
              margin-bottom: 60px;
            }
          }
        }

        .btnView {
          margin-top: 12px;
          display: flex;
          justify-content: center;

          button {
            width: 380px;
            height: 44px;
            border-radius: 6px;
            line-height: 44px;
            padding: 0;
            font-size: 16px;
            color: #fff;
          }
        }
      }

      .query-no {
        display: flex;
        height: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;

        > div > img {
          width: 120px;
        }
      }
    }

    .rightView {
      position: absolute;
      top: 45%;
      right: 25px;
      transform: translateY(-50%);

      .queryView {
        width: 100%;
        height: 100%;
        padding-top: 25px;

        .tView {
          font-family: PingFangSC-Semibold;
          font-size: 20px;
          color: #333;
          line-height: 30px;
          margin-left: -25px;
          margin-bottom: 25px;
          padding: 0 55px;
        }

        .input-contentView {
          /*width: 520px;*/
          /*max-height: 80%;*/
          border-radius: 6px;
          background: rgb(255, 255, 255);
          /*overflow: auto;*/
          box-shadow: #3333 0px 0px 20px 0px;

          .popView-headerView {
            line-height: 40px;
            height: 60px;
            font-family: PingFangSC-Semibold;
            font-size: 24px;
            color: #333333;
            text-align: center;
            padding: 20px 0 5px 0;
          }

          .inputView {
            display: flex;
            position: relative;
            left: 0;
            right: 0;
            width: 386px;
            height: 60px;
            line-height: 60px;
            border-radius: 6px;
            padding: 0 20px;
            background: #fff;
            margin: auto;
            cursor: pointer;
            text-align: center;
            border: 1px solid rgba(220, 222, 224, 100);
            .input {
              background-color: #fff;
            }
            > .label-tip {
              flex: 1;
              display: block;
              font-size: 16px;
              color: #666;
              text-align: center;
            }

            input {
              box-sizing: border-box;
              display: block;
              width: 100%;
              height: 100%;
              padding: 0 0.75rem 0 0.25rem;
              font-size: 20px;
              color: #333442;
              border: 0 none;
              outline: none;
              font-family: DINAlternate-Bold;
              background: #f8f8f8;
            }
          }

          .contentView {
            position: relative;
            margin: auto;
            display: flex;
            width: 424px;

            .keyboardView {
              flex: 1;
              overflow: hidden;
              text-align: center;
              padding: 20px 7px 20px 20px;

              .keyItem {
                position: relative;
                float: left;
                cursor: pointer;
                width: 92px;
                height: 58px;
                overflow: hidden;
                border-radius: 6px;
                // box-shadow: 0 0 3px rgba(220, 220, 220, 0.7);
                background-color: rgba(242, 243, 245, 100);
                margin-right: 12px;
                margin-bottom: 12px;
                .valView {
                  position: absolute;
                  z-index: 11;
                  left: 0;
                  top: 0;
                  cursor: pointer;
                  width: 92px;
                  height: 58px;
                  background-position: center;
                  background-size: 16px 38px;
                  background-repeat: no-repeat;
                }
              }

              .keyItem:nth-child(3n + 3) {
                margin-right: 0;
              }

              .keyItem.key-Del > .valView {
                background-size: 28px;
              }
            }

            .btnView {
              width: 97px;
              padding: 20px 20px 0 5px;

              button {
                position: relative;
                width: 72px;
                height: 128px;
                overflow: hidden;
                border-radius: 6px;
                box-shadow: 0 0 3px rgba(220, 220, 220, 0.7);
                font-size: 18px;
                padding: 0;
                border: none;
                outline: none;
                box-sizing: border-box;
                cursor: pointer;
              }

              button.btn-clean {
                background: transparent;
                color: #000;
                margin-bottom: 12px;
                font-weight: 700;
                background-color: rgba(242, 243, 245, 100);
              }

              button.btn-comp {
                // background: #3370ff;
                background: transparent;
                color: #fff;
                font-weight: 700;
                background-color: #3370ff;
              }
            }
          }
        }
      }
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 30px;
  width: 110px;
  background: #3370ff;
  color: #fff;
}
::v-deep .el-tabs--left .el-tabs__header.is-left {
  margin: 0px;
}
::v-deep .el-tabs--right .el-tabs__nav-wrap.is-right {
  margin: 0px;
}

::v-deep .el-tabs--right .el-tabs__header.is-right {
  margin: 0px;
}
</style>
